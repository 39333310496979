.Report-generator-container {
  width: 56vw;
  height: 80vh;
  /* background-color: #f6f6f6;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); */
  margin-top: 1%;

  display: flex;
  flex-direction: column;
  padding: 0 15% 0 15%;
}

.Report-header {
  height: 8%;
}

.Report-header > p {
  height: 100%;
  font-size: 35px;
  text-align: left;
}
.Report-input {
  display: flex;
  justify-content: space-between;
}
.Report-input .Left-side {
  width: 30%;
}
.Report-input .Right-side {
  width: 30%;
}

.Report-drag-n-drop {
  height: 66%;
  display: flex;
  flex-direction: row;
}

.Report-drop-zone {
  width: 30%;
  border-style: dashed;
  border-color: rgb(0, 0, 0);
  border-width: 2px;
  background-color: rgb(255, 255, 255);
}

.Report-submit {
  height: 8%;
  display: flex;
  justify-content: flex-end;
}

.Create-report-button {
  margin-top: 2%;
}
.Arrow-icon {
  width: 5%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.Preloader {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
}

.align-checkbox {
  display: flex;
  justify-content: center;
  margin-left: 3%;
}

.Report-min-width {
  min-width: 616px;
}

.Right-align {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.Status {
  border-radius: 5px;
  width: fit-content;
  padding: 0 7px 0 7px;
  text-align: center;
  color: black;
  font-size: 12px;
  font-weight: 600;
}

.Vertical-line {
  z-index: -1;
  position: absolute;
  border-right: 1px solid;
  width: 50%;
  top: 0px;
  bottom: 0px;
}

.Table-header > p {
  width: 100%;
}
.Table-header p:nth-child(1) {
  text-align: left;
}
.Table-header p:nth-child(2) {
  text-align: center;
}
.Table-header p:nth-child(3) {
  text-align: right;
}

.Centering-vertical {
  display: flex;
  align-items: center;
}

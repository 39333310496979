.Question-list {
  display: flex;
  flex-direction: column;
}

.Delimiter {
  margin: 40px 0 100px 0;
  width: 880px;
  border: 2px solid #818181;
}

.Questions-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.Items-questions {
  overflow: auto;
  height: 100%;
  width: 40vw;
  background-color: #f6f6f6;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.Items-questions-min-width {
  min-width: 450px;
}

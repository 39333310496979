.Right-align {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.Status {
  border-radius: 5px;
  width: fit-content;
  padding: 0 7px 0 7px;
  text-align: center;
  color: black;
  font-size: 12px;
  font-weight: 600;
}

.Format-text {
  width: 90%;
}

.Format-text > p{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.Form-list-container {
  width: 30%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
}

.Form-list-container > * > p {
  height: 5%;
  text-align: left;
  font-size: 1.5rem;
}

.Report-form-dnd {
  height: 100%;
  width: 100%;
  background-color: rgb(217, 217, 217);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.List {
  flex-grow: 0;
  text-align: left;
  margin: 10px;
  display: flex;
  flex-direction: column;
  width: 21vw;
}

.List-header {
  font-size: 30px;
  text-align: left;
}

.List-items-wrapper {
  background-color: rgb(226, 226, 226);
  height: 700px;
}

.List-items {
  overflow-y: scroll;
  height: 100%;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.User-min-width {
  min-width: 235px;
}

.FilterBanner {
  display: flex;
  width: 90%;
  margin-left: 1%;
}

.FilterBanner .search {
  flex: 1;
  max-width: 30%;
  min-width: 300px;
}
.FilterBanner .switch-container {
  min-width: 100px;
  margin-right: 16px;
  display: flex;
  align-items: center;
}

.Item-card-form-info {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 80%;
}

.Item-card-form-info .date {
  font-weight: lighter;
  font-size: medium;
}

.Item-card-form-info > p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.Body-container.Flex {
  display: flex;
  align-items: center;
}

.Item-card .F-body {
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 15px;
  font-size: 20px;
  cursor: grab;
}

.Item-card .F-body:hover .Drag-handler {
  color: #98c742;
}

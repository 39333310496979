.GDPR-container {
  background-color: #f6f6f6;
  border-radius: 20px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%);
}

.GDPR {
  padding: 5%;
  overflow: auto;
}

.GDPR > h5 {
  text-align: left;
}

.GDPR .body-text {
  text-align: left;
  font-size: 18px;
}

.gdpr-link {
  text-align: left;
  width: fit-content;
}

.gdpr-link:hover {
  text-align: left;
  width: fit-content;
  text-decoration: underline;
}

.Gdpr-modal-body-text-link {
}

.Gdpr-modal-body-text-link:hover {
  text-decoration: underline;
}

.gdpr-success {
  color: white !important;
}

/* Holds all components */
.Main-body {
  width: 90%;
}

/* Contains the list */
.Item-list-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 0 20px;
}
.Items {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  width: 21vw;
  background-color: #f6f6f6;
}
.Item-card {
  display: flex;
  flex-direction: column;
  margin: 2.5%;
  height: 54px;
  padding: 0;

  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.Item-card .text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.Item-card .body {
  background-color: rgb(255, 255, 255);
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 0 15px;
  font-size: 20px;
  cursor: pointer;
}

.Item-card .body-green {
  background-color: #98c742;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 0 15px;
  font-size: 20px;
  cursor: pointer;
}

.Item-card .body:hover {
  color: #98c742;
}

.tree-circles {
  color: rgb(100, 100, 100);
  height: fit-content;
  transform: rotate(90deg);
}

.tree-circles:hover {
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.1);
}

/* Contains cards */
.Card-list {
  flex-grow: 1;
  text-align: left;
  margin: 5px;
}
.Card-list .header {
  font-size: 30px;
  text-align: left;
}
.Card-list .body {
  height: 60vh;
}
.Card-list .Items {
  overflow-y: scroll;
  height: 100%;
  overflow-x: hidden;
}

.Card-list .Card-min-width {
  min-width: 230px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.Card-list .button {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 20px;
  color: rgb(71, 71, 71);
  margin: 10px 10px 10px 10px;
  cursor: pointer;
  width: fit-content;
}
.Card-list .button:hover {
  color: #98c742;
}

.Item-card.Form-info .body:hover {
  color: black;
  cursor: default;
}
.Participant-info-card {
  display: flex;
  flex-direction: column;
  margin: 0 5px 0 5px;
  height: 100px;
  width: 97.5%;
}
.Participant-info-card .body {
  background-color: white;
  display: flex;
  width: auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 15px;
  font-size: 20px;
  cursor: default;
  box-shadow: inset 0 0 2px #656565e2;
}

.Participant-info-card .body > p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Participant-info-card .title {
  text-decoration: none;
  color: rgb(255, 255, 255);
  background-color: #98c742;
  margin-bottom: 0;
  text-align: center;
  width: 33%;
  margin-left: 5px;
  margin-top: 2.5%;
  cursor: default;
}

.Body-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.Body-container-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.Body-container-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.Close-cross-button:hover {
  color: red;
}

.Padding-zero {
  padding: 0;
}

.AnswerOptionContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.Answers {
  width: 88%;
}
.Order {
  min-width: 5%;
}

.Scores {
  width: 7%;
}

.Items-order {
  /* box-shadow: inset 0 0 5px #656565e2; */
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  width: 21vw;
  background-color: #f6f6f6;
}

.Q-Modal-input {
  height: 20%;
  width: 74%;
  display: flex;
  flex-direction: column;
  text-align: start;
}

.Modal-input2 {
  width: 25%;
  display: flex;
  flex-direction: column;
  text-align: start;
}

.modal.Q-modal {
  width: 80vw !important;
}

.Dropzone-list-container {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.blank-space {
  height: 5%;
  min-height: 33px;
}

.Report-drop-zone {
  width: 100%;
  height: 95%;
  border-style: dashed;
  border-color: rgb(85, 85, 85);
  border-width: 2px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.Report-drop-zone.Flex{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Dropzone-inner-box{
  display: flex;
  justify-content: center;
 }

.Dropzone-inner-box > div{
 width: 60%;
 border-style: dashed;
 border-color: rgb(120, 120, 120);
 border-width: 1px;
}

.Dropzone-inner-box > div > p{
  margin: 0 10px 10px 10px;
 }
.Question-container {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  text-align: left;
}

.Question-container .item {
  width: 35vw;
}

.Question-container .mobile {
  width: 80vw !important;
}

.Answer-option-container {
  display: flex;
  flex-direction: column;
}

.Answered-pagination {
  background-color: #98c742 !important;
}

.Partially-answered-pagination {
  background-color: rgb(230, 230, 58) !important;
}

.Pagination-button {
  font-weight: bold;
  background-color: #bababa;
  margin: 0 5px 0 5px;
  border-radius: 50px;
  border-style: none;
}

.Pagination-button-unselected {
  min-width: 25px !important;
  min-height: 25px !important;
  font-size: 0.7rem;
}

.Pagination-button-selected {
  min-width: 40px !important;
  min-height: 40px !important;
  font-size: 1rem;
}

.Pagination-button:focus {
  background-color: #bababa;
}

.Not-selectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

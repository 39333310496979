.Users-list-wrapper {
  display: flex;
  flex-direction: column;
}

.Users-add-user {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 20px;
  color: rgb(71, 71, 71);
  margin: 10px 10px 10px 10px;
  cursor: pointer;
  width: fit-content;
}

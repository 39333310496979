.flex-test-container {
    display:flex;
    flex-direction: column;
}

.Dropzone-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 150px;
    margin-top: 15px;
    border-style: dashed;
    border-radius: 5px;
    border-width: 2px;
    border-color: #919191;
    background-color:#efefef ;
    cursor: pointer;
}

.Dropzone-box:hover {
    color: rgb(119, 155, 52);
    cursor: pointer;
}

.File-name-container {
    display: flex;
    align-items: center;
}

.File-name-container > i {
    font-size: 14px;
    color: #464646;
}

.File-name-container .Reset-button{
    cursor: pointer;
    color: #ff0000;
    margin-right: 8px;
}
.File-name-container .Reset-button:hover{
    color: #df0000;
}


.Container-row {
  display: flex;
  flex-direction: row;
}

.Container-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Container-row .Menu-spacing {
  width: 10%;
}

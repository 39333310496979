.LayoutListPage {
  width: 90%;
  background-color: #f6f6f6;
}

.LayoutListPage-page-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px 40px 40px 40px;
}

.Customer-list-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.Project-list-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.Top-margin {
  margin-top: 30px;
}

.Breadcrumb-container {
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
}

.Breadcrumb-divider-padding {
  padding: 50px;
  margin: 0 20px 0 20px;
}

.Breadcrumb-text {
  font-size: 24px;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  font-weight: bold;
}

.Breadcrumb-text:hover {
  cursor: pointer;
}

.Customer-header-container {
  display: flex;
  justify-content: space-between;
  margin: 0 20px 0 20px;
  align-items: center;
}

.Economy-min-width {
  min-width: 1100px;
}

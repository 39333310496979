.Main-container {
  display: flex;
  justify-content: center;
  height: 100vh;
  padding: 10px;
}

.Content-container {
  flex-direction: column;
  background-color: rgb(227, 227, 227);
  height: 50%;
}
.Content-container .header {
  display: flex;
  justify-content: flex-start;
  margin: 1%;
  height: 15%;
}

.header .logo {
  height: 100%;
}

.Message-body {
  display: flex;
  flex-direction: column;
}

.Message-body .greetings-text {
  font-weight: bold;
  font-size: 20px;
}

.Content-container .Message-body {
  padding: 10px;
  margin: 20px;
  background-color: rgb(255, 255, 255);
  height: 70%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}

.Content-container .message {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  margin: 10px;
  background-color: rgb(255, 255, 255);
  height: 30%;
}

.Content-container .footer a {
  color: #98c742;
}

.Q-Body-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: fit-content;
}

.Question-item {
  text-align: left;
  height: 20%;
  margin: 0.5em auto 0.5em 0;
}

.Question-item-text {
  cursor: pointer;
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  text-decoration-line: underline;
  color: #000000;
  margin: 0;
}
.Question-item-text-bold {
  font-weight: bold;
}

.Question-item-text:hover {
  font-weight: bold;
}

.Formulations-container {
  flex-direction: column;
}

.Item-card-question {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  /* 0px 3px 0px -1px #a4a4a4; */
}
.Item-card-question .text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.Item-card-question .body {
  background-color: rgb(255, 255, 255);
  display: flex;
  height: 68px;
  align-items: center;
  padding: 0 0 0 15px;
  font-size: 20px;
  cursor: grab;
}

.Item-card-question .body:hover .Drag-handler {
  color: #98c742;
}

.Help-page-container {
  /* width: 40vw; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

div > .Help-accordion-content-text {
  text-align: "left";
  width: "40vw";
}

.Collapsible-container {
  width: 70%;
}

.Help-page-intro-text-container {
  text-align: left;
  padding: 2%;
}

div > .customUl > li {
  list-style-type: disc !important;
  margin-left: 2vw;
}

div > .customUl > .customUlNestled > li {
  list-style-type: circle !important;
  margin-left: 4vw;
}

.collapsible-header:hover {
  color: #98c742;
}

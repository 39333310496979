.Question-item {
  text-align: left;
  height: 34px;
  margin: 0.5em auto 0.5em 0;
}

.Question-item-text {
  cursor: pointer;
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  text-decoration-line: underline;
  color: #000000;
  margin: 0;
}
.Question-item-text-bold {
  font-weight: bold;
}

.Question-item-text:hover {
  font-weight: bold;
}

.Formulations-container {
  flex-direction: column;
}

.Item-card-question {
  display: flex;
  flex-direction: column;
  margin: 2.5%;
  padding: 0;
  height: auto;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.Item-card-question .text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.Item-card-question .Q-body {
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 15px;
  height: 100%;
}

.Item-card-question .Q-body:hover .Drag-handler {
  color: #98c742;
}

.Drag-handler {
  margin: 5px;
  border-left: 1px solid rgb(0, 0, 0, 0.1);
}

.container {
  padding: 2rem 0rem;
}
h4 {
  margin: 2rem 0rem;
}
.panel {
  border-radius: 4px;
  padding: 1rem;
  margin-top: 0.2rem;
  background-color: #f5f5f5;
  color: #323b40;
}
.panel.panel-blue {
  background-color: #e0f5ff;
  color: #00a5fa;
}
.panel.panel-big-height {
  min-height: 150px;
}
.item {
  border-radius: 4px;
  padding: 0.5rem;
  margin: 0.2rem;
}
.item.item-blue {
  background-color: #b9e5fe;
  color: #00a5fa;
}
.item.item-green {
  background-color: #b7e0dc;
  color: #019888;
}
.item.item-lime {
  background-color: #c7e8c8;
  color: #42b045;
}
.item.item-yellow {
  background-color: #ffeeba;
  color: #ff9901;
}
.item.item-pink {
  background-color: #fabad0;
  color: #ef075f;
}
.item.item-red {
  background-color: #fec9c6;
  color: #fd3d08;
}
.item.item-big-width {
  min-width: 380px;
}

/* .modal-content{
    background-color: #00a5fa;   
} */

.Button-margin {
  margin: 10px 5px 10px 5px;
}

.Footer-container {
  display: flex;
  justify-content: flex-end;
}

.Modal-text {
  font-size: 40px;
  text-align: center;
  color: #9c9c9c;
  font-weight: bold;
}

.Modal-text-important {
  color: #000000;
}

.input-label {
  display: block;
}

.Modal-gdpr .modal {
  margin-left: 15vw !important;
  margin-right: 15vw !important;
  width: 70vw !important;
}

.Modal-gdpr-mobile .modal {
  width: 100vw !important;
}

.switch {
  position: absolute;
  left: 25%;
}

.Input-container {
  display: flex;
  flex-direction: column;
}
.Input-container .Input-column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Text-left {
  text-align: left;
  white-space: nowrap;
}

.Text-black {
  color: black;
}

.Spacing-top {
  margin-top: 15px;
}

.modal {
  overflow-x: hidden;
  min-width: fit-content;
  transform: none !important;
}

.Trait-container-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.Trait-container-form .item {
  flex-grow: 0;
}
.Trait-container-form li {
  margin: 4px 0;
}


/* questionItemDropDown.css Label {} */
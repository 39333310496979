body {
  font-family: "Titillium Web", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Container-header {
  display: flex;
  justify-content: center;
}

.Header-title {
  margin: 0;
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 61px;
}

.Min-width {
  min-width: 1100px;
}

.Adviser-partner-logo {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  top: 10px;
  height: 0px;
  width: 100%;
  min-width: 1100px;
}

.Adviser-partner-logo > img {
  position: absolute;
  margin-right: 20px;
  width: 100px;
}

.Sign-out {
  margin-right: 150px;
}

.Nav-container {
  display: flex;
  justify-content: space-between;
}

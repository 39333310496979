.customer-selector-container {
  display: flex;
}

.customer-selector-container .customer-selector-col {
  display: flex;
  align-items: center;
}

.customer-selector-col .input-field {
  min-width: 16rem;
}

.customer-selector-col .align-checkbox {
  min-width: 13rem;
}
p {
    margin: 0;
}

.Date-formating {
    font-weight: lighter;
    font-size: medium;
}

.Body-container .Body > p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.Body-container .Body {
    width: 90%;
}
.Sign-out-button {
  background-color: rgba(255, 255, 255, 0);
  border: none;
  cursor: pointer;
  text-decoration: underline;
  color: white;
}
.Sign-out-button:hover {
  color: rgb(0, 0, 0);
}
.Sign-out-button:active:focus {
  background-color: rgba(255, 255, 255, 0);
}
.Sign-out-button:focus {
  background-color: rgba(255, 255, 255, 0);
}

.Cursor-default {
  cursor: default;
}

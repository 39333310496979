.Q-Item-list-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px 20px 20px 20px;
}

.Q-item-list-min-width {
  min-width: 1100px;
}

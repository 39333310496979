.ListItem {
  display: flex;
  flex-direction: column;
  margin: 10px;
  height: 15%;
  padding: 0;
}

.ListItem-body {
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 0 15px;
  font-size: 20px;
  cursor: pointer;
}

.ListItem-body:hover {
  color: #98c742;
  cursor: pointer;
}

.ListItem-selected {
  background-color: #98c742;
}

.ListItem-body:hover {
  color: black;
}

.ListItem-body-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.ListItem-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.Item-card .material-icons.name-icon {
  margin-right: 4px;
  font-size: 16px;
}

.Question-item-drop-down {
  background: #f7f7f7;
  width: 800px;
  height: 826px;
  margin: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.Question-item-drop-down-header {
  display: flex;
  justify-content: space-between;
}

.Question-item-drop-down-header .Flex-column {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.Drop-down-selector {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.54);
  mix-blend-mode: normal;

  height: 33px;
  background: rgba(51, 51, 51, 0.06);
  border-radius: 4px 4px 0px 0px;
  border-style: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);

  /* Temp */
  margin-bottom: 21px;
}

.Drop-down-selector.Language {
  width: 113px;
}

.Drop-down-selector.Trait {
  width: 354px;
}

.Language-drop-down:focus {
  outline: 0;
}

.Question-item-drop-down-body {
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 37px;
  color: #818181;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.Question-item-drop-down-body .Formulation-input-field {
  padding: 0px 12px 0px 12px;
  margin-bottom: 30px;
  width: 512px;
  height: 88px;
  background: rgba(51, 51, 51, 0.06);
  border-style: none;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid #7ab514;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: rgba(0, 0, 0, 0.54);
}

.Question-item-drop-down-body .Align-items-row {
  display: flex;
  flex-direction: row;
}

.Align-items-row .Score-box {
  display: flex;
  flex-direction: column;
  margin: 0 9px 1px 0;
  justify-content: flex-end;
}

.Score-box .Display-score {
  width: 44px;
  height: 20px;
  background: #eaeaea;
  border: 0.5px solid #d6d6d6;
  box-sizing: border-box;
  border-radius: 0px;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  /* Fullösning */
  margin: 1px 0 -1px 0;
}

.Score-box .Adjust-score {
  width: 44px;
  height: 15px;
  background: rgba(51, 51, 51, 0.06);
  border-radius: 0px;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  margin: 3px 0 1px 0;

  cursor: pointer;

  /* Fullösning */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Score-box .Adjust-score:hover {
  background-color: #e4e2e2;
}

.Question-item-drop-down-body .AnswerOption-input-field {
  padding: 0px 12px 0px 12px;
  width: 459px;
  height: 56px !important;
  background: rgba(51, 51, 51, 0.06) !important;
  border-style: none im !important;
  border-radius: 4px 4px 0px 0px !important;
  border-bottom: 1px solid #7ab514 !important;
  margin:0 !important;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: rgba(0, 0, 0, 0.54);
}

.Question-item-drop-down-body .AnswerOption-body {
  width: 80%;
  /* height: 82px; */
}

label {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: #757575;
  margin-bottom: 5px;
}

.Label-large {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #818181;
}

.Question-item-drop-down-body .Selected-language {
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 37px;
  color: #818181;
}

.Question-item-drop-down-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
}

.Button {
  width: 141px;
  height: 41px;
  background: #c4c4c4;
  font-family: Titillium Web;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  border-style: none;
  color: #ffffff;
}

.Button:focus {
  background: rgb(184, 184, 184);
  outline: 0;
}

.Button.Cancel-button:hover {
  background: rgb(184, 184, 184);
}

.Button.Change-button:hover {
  background: rgb(184, 184, 184);
}

.generic-error-container {
  width: 56vw;
  height: 80vh;
  background-color: #f6f6f6;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 1px 5px 0 rgb(0 0 0 / 20%);
  margin: 1% auto 0;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}
.collapsible-body {
  background-color: white;
}

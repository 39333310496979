.Project-list-container > * > p {
  height: 5%;
  text-align: left;
  font-size: 1.5rem;
}

.Report-project {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  height: 95%;
  width: 100%;
  background-color: #f6f6f6;
}

.Project-list-container {
  width: 30%;
  flex-direction: column;
}